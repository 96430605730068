import React, { useState } from "react"
import { useStaticQuery, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Card from "../components/card"
import Job from "../components/job"
import School from "../components/school"
import SkillGroup from "../components/skillgroup"

import Experience from "../components/icons/experience"
import Education from "../components/icons/education"
import Skills from "../components/icons/skills"

import { prepareExperienceData, prepareEducationData, prepareSkillsData } from '../utils/graphql'
import ContactModal from '../components/contactmodal'

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query MyQuery {
      allExperienceJson(sort: {fields: position, order: DESC}) {
        edges {
          node {
            position
            title
            date_started
            date_ended
            company
            company_subtitle
            location
            accomplishments
          }
        }
      }

      allEducationJson {
        edges {
          node {
            position
            school
            location
            degree
            date_started
            date_ended
          }
        }
      }

      allSkillsJson {
        edges {
          node {
            groupName
            groupSkills {
              position
              name
              proficiency
              details
            }
          }
        }
      }
    }
  `)

  const experienceData = prepareExperienceData(data)
  const educationData = prepareEducationData(data)
  const skillsData = prepareSkillsData(data)

  const [canShow, setCanShow] = useState(false)

  return (
    <Layout>
      <SEO title="Resume" />
      <Card title={`About Me`} colorSet={ {color: 'white', backgroundColor: 'peacock-yellow'}}>
        <div className="px-6 mb-4">
          <span className="text-xl">I'm an experienced <span className="font-bold">full-stack developer</span> with over a <span className="font-bold">decade of experience</span> across a number of different industries.</span>
        </div>
        <div className="px-6">
          <div className="text-xl mb-4">I am available for full-time, contract, or consulting work.</div>

          <div className="px-6 py-6 mt-2 bg-gray-600 rounded-md">
            <div className="text-3xl font-bold mb-2">Ready to talk?</div>
            <button type="button" onClick={ () => setCanShow(true) } className="focus:outline-none bg-peacock-light-green text-gray-700 text-lg font-semibold px-4 py-3 rounded shadow-md">Contact Me</button>
          </div>
        </div>
      </Card>

      <Card icon={Experience} title={`Experience`}>
        <div className="grid gap-8 grid-cols-1 md:grid-cols-2">
          {
            experienceData.map(job => (
              <div
                key={job.position}
                className="flex flex-col border border-gray-600 bg-white shadow-md px-6 py-5">
                <Job job={job} />
              </div>
            ))
          }
        </div>
      </Card>

      <Card icon={Skills} title={`Skills`} colorSet={ { color: 'white', backgroundColor: 'peacock-light-green'} }>
        <div className="grid gap-8 grid-cols-1 sm:grid-cols-2">
          {
            skillsData.map(skillGroup => (
              <div
                key={skillGroup.groupName}
                className="flex flex-col border border-gray-600 bg-white shadow-md px-6 py-5"
              >
                <SkillGroup skillGroup={skillGroup} />
              </div>
            ))
          }
        </div>
      </Card>


      <Card icon={Education} title={`Education`} colorSet={ { color: 'white', backgroundColor: 'peacock-yellow'} }>
        <div className="grid gap-8 grid-cols-1 sm:grid-cols-2">
          {
            educationData.map(school => (
              <div
                key={school.position}
                className="border border-gray-600 bg-white shadow-md px-6 py-5">
                <School school={school} />
              </div>
            ))
          }
        </div>
      </Card>
      <ContactModal show={canShow} setCanShow={setCanShow} />
    </Layout>
  )
}

export default IndexPage
