import React from 'react'

const Card = (props) => {
    const defaultColorSet = { color: 'white', backgroundColor: 'indigo-500' }
    const { icon, title, children, colorSet = defaultColorSet } = props
    const { color, backgroundColor } = colorSet

    return (
        <div className={`text-${color} bg-${backgroundColor} px-10 py-10`}>
            <div className="flex items-center mb-4 px-4 max-w-screen-xl mx-auto">
                { icon ? icon() : null }
                <div className="ml-2">
                    <h1 className="text-4xl font-semibold text-shadow">{ title }</h1>
                </div>
            </div>
            <div className="max-w-screen-xl mx-auto">
                { children }
            </div>
        </div>
    )
}

export default Card
