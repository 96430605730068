import React from 'react'

const Education = () => {
    return (
        <div>
            <div>
                <svg
                    className="h-24 w-24 fill-current text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    version="1.1"
                    x="0px"
                    y="0px"
                    viewBox="-370 248.3 100 100">
                    <polygon points="-311.2,299.6 -320.1,308.5 -328.3,300.3 -351.3,300.3 -351.3,336 -324.4,336 -324.4,320.6 -320.1,324.9 -303,307.8   "/>
                    <path d="M-328.2,285.6c0-5.9-4.8-10.6-10.6-10.6c-5.8,0-10.6,4.8-10.6,10.6c0,5.8,4.8,10.6,10.6,10.6  C-333,296.2-328.2,291.5-328.2,285.6z"/>
                    <rect x="-320" y="275" width="28.9" height="3.2"/>
                    <rect x="-312.8" y="282.2" width="28.9" height="3.2"/>
                    <rect x="-316.4" y="289.4" width="21.7" height="3.2"/>
                    <polygon points="-365,260.5 -365,321.6 -356.2,321.6 -356.2,318.4 -361.8,318.4 -361.8,263.7 -278.2,263.7 -278.2,318.4   -309.2,318.4 -309.2,321.6 -275,321.6 -275,260.5 "/>
                </svg>
            </div>
        </div>
    )
}

export default Education
