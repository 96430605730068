import React from 'react'

const Experience = () => {
    return (
        <div>
            <div>
                <svg
                    className="h-24 w-24 fill-current text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    version="1.1"
                    viewBox="60 0 200 330"
                    x="0px"
                    y="0px"
                    fillRule="evenodd"
                    clipRule="evenodd">
                    <g>
                        <path className="fil0" d="M62 42c16,0 29,13 29,29 0,16 -13,29 -29,29 -16,0 -29,-13 -29,-29 0,-16 13,-29 29,-29zm204 -17l2 6c1,0 3,0 4,0l2 -6c1,-2 8,0 7,2l-1 6c1,0 3,1 4,2l4 -4c2,-2 7,3 5,5l-4 5c1,1 2,2 3,4l5 -2c3,0 5,7 2,7l-5 2c0,2 0,3 0,5l5 1c3,1 1,8 -2,8l-5 -2c-1,2 -2,3 -3,4l4 4c2,2 -3,8 -5,6l-4 -4c-1,1 -3,1 -4,2l1 6c1,2 -6,4 -7,1l-2 -5c-1,0 -3,0 -4,0l-2 5c-1,3 -8,1 -7,-1l1 -6c-1,-1 -2,-1 -4,-2l-4 4c-2,2 -7,-4 -5,-6l4 -4c-1,-1 -2,-2 -3,-4l-5 2c-3,0 -5,-7 -2,-8l5 -1c0,-2 0,-3 0,-5l-5 -2c-3,0 -1,-7 2,-7l5 2c1,-2 2,-3 3,-4l-4 -5c-2,-2 3,-7 5,-5l4 4c2,-1 3,-2 4,-2l-1 -6c-1,-2 6,-4 7,-2zm4 10c11,0 19,9 19,19 0,11 -8,19 -19,19 -10,0 -19,-8 -19,-19 0,-10 9,-19 19,-19zm-85 -24l10 -2 2 9c2,0 4,0 6,0l3 -9 9 3 -2 9c2,0 3,1 5,3l7 -7 7 8 -7 6c1,2 2,3 3,5l9 -2 2 10 -9 2c0,2 0,4 0,6l9 2 -3 10 -9 -2c0,1 -1,3 -3,5l7 6 -8 7 -6 -6c-2,1 -3,2 -5,3l2 8 -10 3 -2 -9c-1,0 -1,0 -2,0 -2,0 -3,0 -4,0l-2 9 -10 -3 2 -9c-1,-1 -3,-2 -5,-3l-6 6 -7 -7 6 -6c-1,-2 -2,-4 -3,-6l-8 3 -3 -10 9 -2c0,-2 0,-4 0,-6l-9 -3 3 -9 9 2c1,-2 2,-3 3,-5l-6 -7 7 -7 6 7c2,-1 4,-2 5,-3l-2 -9zm15 18c10,0 19,9 19,19 0,11 -9,20 -19,20 -11,0 -20,-9 -20,-20 0,-10 9,-19 20,-19zm-3 61l8 0 0 6c0,0 1,0 2,0l3 -5 6 4 -3 5c0,1 1,1 1,1l6 -3 4 7 -6 3c0,1 0,1 1,2l6 0 0 7 -6 0c-1,1 -1,2 -1,2l6 3 -4 7 -6 -3c0,0 -1,1 -1,1l3 6 -6 3 -3 -5c-1,0 -2,0 -2,0l0 7 -8 0 0 -7c0,0 -1,0 -2,0l-3 5 -6 -3 3 -6c-1,0 -1,-1 -1,-1l-6 3 -4 -7 6 -3c0,0 -1,-1 -1,-2l-6 0 0 -7 6 0c0,-1 1,-1 1,-2l-6 -3 4 -7 6 3c0,0 0,0 1,-1l-3 -5 6 -4 3 5c1,0 2,0 2,0l0 -6zm4 12c6,0 11,5 11,12 0,6 -5,11 -11,11 -6,0 -12,-5 -12,-11 0,-7 6,-12 12,-12zm-56 85l125 0 0 5 -125 0 0 -5zm21 -19l56 0 13 -50c2,-7 12,-5 10,2l-13 54c-1,3 -2,7 -7,7l-59 0c-9,0 -9,-13 0,-13zm-75 -14l3 49 59 0c14,0 24,4 27,18l19 87c4,17 -25,23 -28,8l-18 -78 -85 0 0 0c-12,0 -25,-9 -26,-26l-5 -77c-2,-21 18,-34 37,-24l56 37 45 -11c13,-3 16,17 5,20l-50 14c-6,0 -11,-1 -15,-4l-24 -13z" />
                    </g>
                </svg>
            </div>
        </div>
    )
}

export default Experience
