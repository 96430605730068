import React from 'react'

import Skill from '../components/skill'

const SkillGroup = ({skillGroup}) => {
    const { groupName, groupSkills } = skillGroup

    return (
        <div>
            <span className="text-peacock-dark-green font-semibold text-2xl">{ groupName }</span>

            <div className="mt-5 text-gray-700">
                {
                    groupSkills.map(skill => (
                        <Skill key={skill.position} skill={skill} />
                    ))
                }
            </div>
        </div>
    )
}

export default SkillGroup
