import React from 'react'
// import { useStaticQuery, graphql } from 'gatsby'
// import Img from 'gatsby-image'

const School = ({school: schoolData}) => {
    // const imageData = useStaticQuery(graphql`
    //     query {
    //         clemsonLogo: file(relativePath: { eq: "clemson_logo.png"}) {
    //             childImageSharp {
    //                 fluid(maxWidth: 350) {
    //                     ...GatsbyImageSharpFluid
    //                 }
    //             }
    //         }
    //     }
    // `)
    const {school, location, degree, date_started, date_ended} = schoolData

    return (
        <>
            <div>
                <div className="text-md">
                    <div className="font-semibold leading-tight text-peacock-yellow">{degree}</div>
                    <div className="text-sm leading-tight text-gray-700">{school}</div>
                    <div className="text-sm leading-tight text-gray-700">{location}</div>
                    <div className="text-sm leading-tight text-gray-700">{date_started} - {date_ended}</div>
                </div>
            </div>
        </>
    )
}

export default School
