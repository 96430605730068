import React from 'react'

const Skills = () => {
    return (
        <div>
            <div>
                <svg
                    style={{transform: "scale(1.4) translateX(-3px)"}}
                    className="h-24 w-24 fill-current text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    version="1.1"
                    x="0px"
                    y="0px"
                    viewBox="0 0 96 120">
                        <polygon points="25.99,55.585 42.686,33.049 41.062,31.718 21.619,53.164 25.99,53.164 " />
                        <circle cx="15.563" cy="69.326" r="3.193" />
                        <path d="M24.49,58.54v-3.876h-3.518H15.53h-3.159v4.313c0.957-0.279,1.967-0.437,3.013-0.437H24.49z" />
                        <rect x="17.03" y="30.471" width="2.442" height="22.693" />
                        <circle cx="80.437" cy="69.326" r="3.193" />
                        <path d="M80.615,60.04H15.384c-5.12,0-9.285,4.166-9.285,9.286s4.165,9.285,9.285,9.285h65.231c5.12,0,9.286-4.165,9.286-9.285  S85.735,60.04,80.615,60.04z M15.563,74.019c-2.587,0-4.692-2.105-4.692-4.692c0-2.588,2.105-4.693,4.692-4.693  s4.692,2.105,4.692,4.693C20.256,71.913,18.15,74.019,15.563,74.019z M80.437,74.019c-2.587,0-4.692-2.105-4.692-4.692  c0-2.588,2.105-4.693,4.692-4.693s4.692,2.105,4.692,4.693C85.129,71.913,83.023,74.019,80.437,74.019z" />
                        <path d="M85.242,17.465c-2.131,0.352-7.998,2.337-7.998,13.511v23.264h7.998V17.465z" />
                        <path d="M86.676,60.41v-4.671H75.744h-4.772v2.801h9.644C82.86,58.54,84.947,59.231,86.676,60.41z" />
                        <path d="M73.138,54.239c-0.226-0.845-0.134-1.559-0.006-2.354c0.192-1.198,0.41-2.556-0.6-4.393c-1.011-1.838-2.274-2.381-3.39-2.86  c-1.022-0.439-1.905-0.819-2.667-2.204c-0.764-1.389-0.611-2.338-0.436-3.438c0.192-1.199,0.41-2.559-0.602-4.397  c-1.012-1.84-2.277-2.384-3.394-2.864c-1.023-0.44-1.907-0.82-2.67-2.208c-0.765-1.39-0.613-2.34-0.437-3.44  c0.191-1.199,0.409-2.559-0.603-4.398l-1.314,0.723c0.764,1.389,0.611,2.339,0.436,3.438c-0.192,1.2-0.409,2.561,0.604,4.4  c1.012,1.839,2.276,2.383,3.392,2.863c1.024,0.44,1.908,0.821,2.672,2.209s0.611,2.338,0.436,3.438  c-0.192,1.199-0.41,2.559,0.602,4.398c1.011,1.837,2.274,2.38,3.389,2.859c1.022,0.439,1.906,0.819,2.668,2.205  s0.609,2.334,0.433,3.433c-0.122,0.763-0.256,1.603-0.068,2.592" />
                </svg>
            </div>
        </div>
    )
}

export default Skills
