import { pipe, path, map } from 'ramda'

export const prepareExperienceData = pipe(
    path(['allExperienceJson', 'edges']),
    map(path(['node']))
)

export const prepareEducationData = pipe(
    path(['allEducationJson', 'edges']),
    map(path(['node']))
)

export const prepareSkillsData = pipe(
    path(['allSkillsJson', 'edges']),
    map(path(['node']))
)