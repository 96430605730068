import React from 'react'

const Job = ({job}) => {
    const {title, date_started, date_ended, company, company_subtitle, location, accomplishments} = job

    return (
        <>
            <div className="flex flex-col">
                <span className="font-semibold text-indigo-600">{company}</span>
                <span className="text-xs text-gray-500">{company_subtitle ? company_subtitle : `\u00A0`}</span>
            </div>

            <span className="text-xs text-gray-700">{`${date_started} - ${date_ended ? date_ended : "Present"}`}</span>
            <span className="text-xs text-gray-700">{location}</span>
            <span className="font-semibold mt-4 text-gray-900">{title}</span>

            <ul className="mt-4 text-sm text-gray-800">
                {
                    accomplishments.map(line => (
                        <li key={line} className="list-disc mx-5">{line}</li>
                    ))
                }
            </ul>
        </>
    )
}

export default Job
