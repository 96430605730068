import React, { useEffect, useRef } from 'react'

const ContactModal = (props) => {
    const { show, setCanShow } = props
    const formRef = useRef(null)

    useEffect(() => {
        if (show) {
            document.body.classList.add("overflow-hidden")
        } else {
            document.body.classList.remove("overflow-hidden")
        }
    }, [show])

    const handleSubmit = (event) => {
        event.preventDefault()
        setCanShow(false)
    }

    const handleClose = (event) => {
        event.preventDefault()
        formRef.current.reset()
        setCanShow(false)
    }

    return (
        <div className={`${ show ? "block" : "hidden" } absolute bg-overlay top-0 left-0 min-w-full min-h-screen h-full`}>
            <div className="bg-gray-400 opacity-100 z-50 fixed w-11/12 md:w-9/12 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                <div className="relative">
                    <span aria-label="Close Modal" role="button" tabIndex={0} onClick={handleClose} onKeyPress={handleClose} className="close-button text-3xl mr-2 -mt-2 cursor-pointer absolute top-0 right-0"></span>
                    <form ref={formRef} name="contact-me" data-netlify="true">
                        <input type="hidden" name="form-name" value="contact-me" />
                        <div className="px-8 py-6">
                            <div>
                                <h1 className="font-bold text-3xl text-peacock-light-green text-shadow">Let's Talk</h1>
                            </div>
                            <div className="mt-8">
                                <div className="font-semibold text-gray-800 tracking-wider">Your Email:</div>
                                <input name="email" type="email" required className="border border-gray-700 rounded-lg w-full px-2 py-2" />
                            </div>
                            <div className="mt-8">
                                <div className="font-semibold text-gray-800">A short description of your project:</div>
                                <textarea name="project-description" required className="border border-gray-700 rounded-lg w-full px-2 py-2" cols="30" rows="10"></textarea>
                            </div>
                            <div className="mt-8">
                                <button type="submit" onSubmit={handleSubmit} className="border border-gray-700 bg-white font-semibold w-full px-3 py-2 rounded-lg">Send</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default ContactModal
