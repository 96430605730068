import React from 'react'

const Skill = ({skill}) => {
    const { name, details } = skill

    return (
        <dl className="text-md font-semibold mt-2">
            <dt>{ name }</dt>
            <dd className="text-sm font-normal">
                {
                    details && details.join(", ")
                }
            </dd>
        </dl>
    )
}

export default Skill
